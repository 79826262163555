import React from "react";
import arrowRightSvg from "../img/arrow-right.svg";
import arrowLeftSvg from "../img/arrow-left.svg";

const SlickArrow = ({ className, style, onClick, direction }) => (
  <div
    className={className}
    style={{ ...style, display: "block", width: "3rem", height: "3rem" }}
    onClick={onClick}
  >
    {direction === "right" ? (
      <img src={arrowRightSvg} alt="right arrow"/>
    ) : (
      <img src={arrowLeftSvg} alt="left arrow"/>
    )}
  </div>
);

export default SlickArrow;
