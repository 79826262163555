import React from "react";

const LoadMoreButton = ({ className, enabled = false, onClick }) => {
  if (!enabled) return null;

  return (
    <button
      className={`load-more ${className}`}
      onClick={onClick}
    >
      Load more
    </button>
  );
};

export default LoadMoreButton;
