export const getSlugToken = (slug) => {
  if (!slug) return "";
  const splits = slug.split("/");
  if (splits.length === 0) return;
  return splits[splits.length - 2];
};

export const isNotDraft = (content) => {
  return !content.draft;
};

export const isPublished = (content) => {
  const now = new Date().getTime();
  return now >= new Date(content.date).getTime();
};
