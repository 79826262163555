import { graphql, StaticQuery, Link } from "gatsby";
import React, { useState } from "react";
import { useWindowSize } from "../hooks/useWindowSize";
import PreviewCompatibleImage from "../components/PreviewCompatibleImage";
import { isNotDraft, isPublished } from "../utils/content-utils";
import LoadMoreButton from "./LoadMoreButton";

export const SpeakingRollTemplate = ({ speakingEvents = [] }) => {
  const [page, setPage] = useState(1);
  const { width } = useWindowSize();
  const isMobile = width <= 900;

  const pageSize = isMobile ? 6 : 9;

  const visibleEvents = speakingEvents
    .filter(isPublished)
    .slice(0, pageSize * page);
  const isLoadMoreEnabled = pageSize * page <= visibleEvents.length;

  return (
    <>
      <div className="event-list">
        {visibleEvents.map((event, index) => (
          <Link className="column" to={event.url || "#"} key={index}>
            <div className="ratio-1-1">
              <PreviewCompatibleImage
                imageInfo={event.image}
                alt={event.title}
                style={{ position: "absolute", borderRadius: "0.5rem" }}
              />
            </div>
            <p>{event.title}</p>
          </Link>
        ))}
      </div>
      <LoadMoreButton
        enabled={isLoadMoreEnabled}
        onClick={() => {
          setPage(page + 1);
        }}
      />
    </>
  );
};

const SpeakingRoll = ({ data }) => {
  const { events } = data;
  const speakingEvents = events.edges
    .map((edge) => edge.node.frontmatter)
    .filter(isNotDraft);
  return <SpeakingRollTemplate speakingEvents={speakingEvents} />;
};

export default (props) => (
  <StaticQuery
    render={(data) => <SpeakingRoll data={data} {...props} />}
    query={graphql`
      query SpeakingRoll {
        events: allMarkdownRemark(
          sort: { order: DESC, fields: [frontmatter___date] }
          filter: {
            frontmatter: { type: { eq: "speaking-event" }, draft: { ne: true } }
          }
        ) {
          edges {
            node {
              frontmatter {
                draft
                date
                title
                image
                url
              }
            }
          }
        }
      }
    `}
  />
);
