import React from "react";
import { graphql } from "gatsby";
import Slider from "react-slick";
import Layout from "../components/Layout";
import SectionTitle from "../components/SectionTitle";
import SlickArrow from "../components/SlickArrow";
import SpeakingRoll from "../components/SpeakingRoll";

export const SpeakingsPageTemplate = ({
  subtitle,
  title,
  testimonialTitle,
  testimonials,
  email,
  phone,
}) => {
  const sliderSetting = {
    arrows: true,
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <SlickArrow direction="right" />,
    prevArrow: <SlickArrow direction="left" />,
  };

  return (
    <div className="speakings-page container">
      <section className="speaking-roll-wrapper section">
        <SectionTitle heading={title} subHeading={subtitle} />
        <SpeakingRoll />
      </section>

      {testimonials && testimonials.length > 0 && (
        <section className="testimonials section">
          <SectionTitle heading={testimonialTitle} />
          <Slider {...sliderSetting}>
            {testimonials.map((tm, index) => (
              <div key={index}>
                <div className="tm-content">
                  <p>{tm.quote}</p>
                  <p>
                    <i>
                      <b>{tm.author}</b>
                    </i>
                  </p>
                </div>
              </div>
            ))}
          </Slider>
        </section>
      )}

      <div className="enquiry section">
        <SectionTitle heading="ENQUIRIES" />
        <div className="info">
          <strong>E:</strong> {email}
        </div>
        <div className="info">
          <strong>T:</strong> {phone}
        </div>
      </div>
    </div>
  );
};

const SpeakingsPage = ({ data }) => {
  const { config } = data;
  const { frontmatter } = config;
  return (
    <Layout title={frontmatter.title}>
      <SpeakingsPageTemplate
        subtitle={frontmatter.subtitle}
        title={frontmatter.title}
        testimonialTitle={frontmatter.testimonial_title}
        testimonials={frontmatter.testimonials}
        email={frontmatter.email}
        phone={frontmatter.phone}
      />
    </Layout>
  );
};

export default SpeakingsPage;

export const speakingsPageQuery = graphql`
  query SpeakinsPage($id: String!) {
    config: markdownRemark(id: { eq: $id }) {
      frontmatter {
        subtitle
        title
        testimonial_title
        testimonials {
          quote
          author
        }
        email
        phone
      }
    }
  }
`;
